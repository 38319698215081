import { mountSelects, setFormFieldError, removeFormFieldsError, clearFormField } from 'JS/components/_form.js';
import { post } from 'JS/components/_request.js';

(function () {
    

    window.addEventListener('DOMContentLoaded', () => {
        const formWrappers = document.querySelectorAll('.js-form-newsletter');

        if(formWrappers.length>0){
            formWrappers.forEach((el)=>{
                const form = el.querySelector('form');
    
                if(form){
                    form.addEventListener('submit', async function(e){
                        e.preventDefault();
                        
                        if(form.classList.contains('submitting')) return;
                        form.classList.add('submitting');
                        
                        let email_el = form.querySelector('[name="email"]');
                        if(!email_el) return;
    
                        // let recaptcha_el = form.querySelector('#g-recaptcha-response');
                        // if(!recaptcha_el) return;
    
                        let formMessage = form.querySelector('.js-form-message');
                        if(!formMessage) return;
    
                        let data = {
                            email : email_el.value,
                            term : true,
                        }
    
                        let formData = {
                            "action" : "ayat_form_newsletter",
                            data : JSON.stringify(data),
                        }
    
                        removeFormFieldsError(form, data);
                        formMessage.classList.add('hide');
                        formMessage.classList.remove('success');
                        formMessage.classList.remove('error');
    
                        let res = await post(formData);
    
                        if(res.success){
                            let message = 'Success';
                            if(res.data.message){
                                message = res.data.message;
                            }
                            formMessage.classList.remove('hide');
                            formMessage.classList.add('success');
                            formMessage.innerHTML = `<p>${message}</p>`;
                            clearFormField(form, data);
                        }
                        else{
                            let message = 'Something went wrong.';
                            if(res.data.message){
                                message = res.data.message;
                            }
                            formMessage.innerHTML = `<p>${message}</p>`;
                            formMessage.classList.remove('hide');
                            formMessage.classList.add('error');
            
                            let grecaptchaChecked = true;
                            if(res.data.errors){
                                for (const [key, value] of Object.entries(res.data.errors)) {
                                    if (key=='grecaptchaResponse') {
                                        let field = form.querySelector(`.g-recaptcha`);
                                        setFormFieldError(field, value);
                                        grecaptchaChecked = false;
                                        continue;
                                    }
            
                                    let field = form.querySelector(`[name="${key}"]`);
                                    setFormFieldError(field, value);
                                }
                            }
                            
                            if(grecaptchaChecked){
                                if(grecaptcha){
                                    grecaptcha.reset();
                                }
                            }
                        }
                        form.classList.remove('submitting');
                    });
                }
            });
        }
    })

})();