import { setCookie, slideUp, slideDown } from 'JS/components/_functions';
(function () {
    const header = document.querySelector('.js-header');

    if (!header) return;

    let curScroll = window.scrollY || document.scrollTop;
    const scrollHeight = 100;

    const headerHandler = () => {
        curScroll = window.scrollY || document.scrollTop;

        if (curScroll >= scrollHeight) {
            header.classList.add('default');
        } else {
            header.classList.remove('default');
        }
    }

    document.addEventListener('scroll', () => {
        headerHandler()
    })

    const buttonToggleNav = document.querySelector('.js-btn-toggle-menu');

    if (buttonToggleNav) {
        buttonToggleNav.addEventListener('click', () => {
            if (header.classList.contains('nav-open')) {
                header.classList.remove('nav-open');
                document.body.classList.remove('fixed');
            }
            else {
                header.classList.add('nav-open');
                document.body.classList.add('fixed');
            }
        })
    }

    window.addEventListener('resize', () => {
        if (!window.matchMedia("(max-width: 1024px)").matches) {
            header.classList.remove('nav-open');
            document.body.classList.remove('fixed');
        }
    })

    const buttonToggleSubmenu = document.querySelectorAll('.menu-item a .drop');
    if(buttonToggleSubmenu.length>0){
        buttonToggleSubmenu.forEach((el)=>{
            el.addEventListener('click', function(e){
                e.preventDefault();
                let item = this.closest('.menu-item');
                if(item){
                    let submenu = item.querySelector('.sub-menu');
                    if(item.classList.contains('sub-menu-open')){
                        item.classList.remove('sub-menu-open');
                        if(submenu){
                            slideUp(submenu);
                        }
                    }
                    else{
                        item.classList.add('sub-menu-open');
                        if(submenu){
                            slideDown(submenu);
                        }
                    }
                    
                }
            });
        });
    }

    const popups = document.querySelectorAll('.js-popup');
    if(popups.length>0){
        popups.forEach((popup)=>{
            popup.addEventListener('click', function(e){
                e.preventDefault();
                const popupContent = popup.querySelector('.popup-content');
                const popupBtnClose = popup.querySelector('.btn-close-popup');
                if(popupContent){
                    if(!popupContent.contains(e.target) || popupBtnClose.contains(e.target)){
                        popup.classList.remove('show');

                        setTimeout(function(){
                            const popupContent = popup.querySelector('.js-popup-content');
                            if(popupContent){
                                popupContent.innerHTML = '';
                            }
                        }, 200);
                    }
                }
            });
        })
    }


    window.addEventListener('DOMContentLoaded', () => {
        headerHandler();
    })
    
})();